import React, { useState, useEffect } from "react";
import axios from "axios";

import plus from "../../img/plusIcon.png";

import { useForm } from "react-hook-form";
import { ArtWork } from "./validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";

import "./workStyle.css";

const AddArtist = () => {
  const postURL = "https://api.artfactory.am/api/works/add/work";

  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({ resolver: yupResolver(ArtWork) });

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  });

  const [image, setImage] = useState("");

  const convert2base64 = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result.toString());
    };

    reader.readAsDataURL(file);
  };

  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    console.log(e.target.files);
    setFile(e.target.files[0]);
    convert2base64(e.target.files[0]);
    // convert2base64(file);
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("Photos", data.Photos[0]);
    formData.append("ArtistId", data.ArtistId);
    formData.append("WorkName", data.WorkName);
    formData.append("Material", data.Material);
    formData.append("Size", data.Size);
    formData.append("About", data.About);
    formData.append("Price", data.Price);

    for (var p of formData) {
      console.log(p);
    }
    await axios
      .post(postURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          ApiKey: data.Password,
        },
      })
      .then((X) => {
        console.log("here");
      });
    // console.log("formData:::", file);
  };

  return (
    <div className="addArtistContainer">
      <h1>Add Work</h1>

      <div className="section">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 style={{ display: "flex", justifyContent: "center" }}>Preview</h2>
          <div className="topSection">
            <div className="slider">
              <img className="workImg" src={image} />
            </div>
            <div className="listHolder">
              <ul className="list1">
                <li>
                  <div className="NameYear">
                    <div className="secHolder">
                      <h2 className="title1">Artwork Name</h2>
                      <div style={{ marginBottom: 10 }}>
                        <input
                          style={{ width: "100px", height: "20px" }}
                          type="text"
                          {...register("WorkName", { required: true })}
                        />
                        {errors.WorkName && <p>{errors.WorkName.message}</p>}
                      </div>
                    </div>
                    {/* <h2 className=title}>{year}</h2> */}
                  </div>
                  <p className="item1" style={{ color: "#000000" }}>
                    By Artist Name: author
                  </p>
                </li>
                <li>
                  <ul className="NameYear">
                    <ul className="infoList">
                      <li className="item1">Size:</li>
                      <li className="item1">
                        <div style={{ marginBottom: 10 }}>
                          <input
                            style={{ width: "100px", height: "20px" }}
                            type="text"
                            {...register("Size", { required: true })}
                          />
                          {errors.Size && <p>{errors.Size.message}</p>}
                        </div>
                      </li>
                    </ul>
                    <ul className="infoList">
                      <li className="item1">Material:</li>
                      <li className="item1">
                        <div style={{ marginBottom: 10 }}>
                          <input
                            style={{ width: "100px", height: "20px" }}
                            type="text"
                            {...register("Material", { required: true })}
                          />
                          {errors.Material && <p>{errors.Material.message}</p>}
                        </div>
                      </li>
                    </ul>
                  </ul>
                </li>
                <li>
                  <ul className="infoList">
                    <h2 className="item1">Price:</h2>
                    <h2 className="item1">
                      <div style={{ marginBottom: 10 }}>
                        <input
                          style={{ width: "100px", height: "20px" }}
                          type="text"
                          {...register("Price", { required: true })}
                        />
                        {errors.Price && <p>{errors.Price.message}</p>}
                      </div>{" "}
                    </h2>
                  </ul>
                </li>
                <li className="item1">
                  <button style={{ padding: "13px 20px" }} className="btn">
                    Request to buy
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h2>About Artwork</h2>
            <p>
              <div style={{ marginBottom: 10 }}>
                <input
                  type="text"
                  style={{ height: "100px" }}
                  {...register("About", { required: true })}
                />
                {errors.About && <p>{errors.About.message}</p>}
              </div>
            </p>
          </div>
          <div style={{ marginBottom: 10 }}>
            <label>Artist Id</label>
            <input type="text" {...register("ArtistId", { required: true })} />
            {errors.ArtistId && <p>{errors.ArtistId.message}</p>}
          </div>

          <div>
            <label>Photos</label>
            <input
              type="file"
              name="Photos"
              multiple
              onInput={handleChange}
              {...register("Photos", { required: true })}
            />
            {errors.Photos && <p>{errors.Photos.message}</p>}
          </div>
          <label>Password</label>
          <input
            type="Password"
            {...register("Password", { required: true })}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <button type="submit">Submit</button>
          </div>
        </form>
        <button className="buttonPlus" onClick={() => setOpen(true)}>
          <img src={plus} alt="" />
        </button>
        <h2 style={{ fontFamily: "montserrat", fontWeight: "400" }}>
          Add Artists
        </h2>
      </div>
    </div>
  );
};

export default AddArtist;
