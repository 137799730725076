import React, { useState } from "react";

import axios from "axios";

import { useForm } from "react-hook-form";
import { EventSchema } from "./validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";

import "./eventStyle.css";

const AddEvent = () => {
  const postURL = "https://api.artfactory.am/api/Event/add/event";

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({ resolver: yupResolver(EventSchema) });

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  });

  const [image, setImage] = useState("");

  const convert2base64 = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result.toString());
    };

    reader.readAsDataURL(file);
  };

  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    console.log(e.target.files);
    setFile(e.target.files[0]);
    convert2base64(e.target.files[0]);
  };

  const onSubmit = async (data) => {
    const date = new Date(
      Date.UTC(
        data.DateTime?.substring(0, 4),
        data.DateTime?.substring(5, 6),
        data.DateTime?.substring(5, 7),
        data.DateTime?.substring(8, 10),
        data.DateTime?.substring(11, 13),
        data.DateTime?.substring(14, 16),
        data.DateTime?.substring(20, 23)
      )
    );
    const formData = new FormData();
    formData.append("Photo", data.Photo[0]);
    formData.append("Type", data.Type);
    formData.append("Location", data.Location);
    formData.append("Title", data.Title);
    formData.append("Time", data.Time);
    formData.append("Name", data.Name);
    formData.append("FundedBy", data.FundedBy);
    formData.append("About", data.About);

    for (var p of formData) {
      console.log(p);
    }
    await axios
      .post(postURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          ApiKey: data.Password,
        },
      })
      .then((X) => {
        console.log("here");
      });
  };

  return (
    <div className="addArtistContainer">
      <h1>Add Event</h1>

      <div className="section">
        <h2>Preview</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="topContainer">
            <div style={{ marginBottom: 10 }}>
              <label>Name</label>
              <input
                type="text"
                style={{ width: "100px", height: "20px" }}
                {...register("Name", { required: true })}
              />
              {errors.Name && <p>{errors.Name.message}</p>}
            </div>
            <div className="topSection">
              <img className="mainImgEvet" src={image} alt="" />
              <div className="description">
                <div className="dateTimeContainer">
                  <ul className="dateTime">
                    <li className="strong">Data:</li>
                    <li className="strong">Time:</li>
                  </ul>
                  <ul className="dateTime">
                    <li className="text">
                      <div style={{ marginBottom: 10 }}>
                        <input
                          style={{ width: "150px", height: "20px" }}
                          type="text"
                          {...register("Time", { required: true })}
                        />
                        {errors.Time && <p>{errors.Time.message}</p>}
                      </div>
                      <p style={{ fontSize: "12px" }}>
                        (data form: 2018-03-29T13:34:00)
                      </p>
                    </li>
                    <li className="text"></li>
                    {/* <li className="text">{date.substring(0, 10)}</li>
                  <li className="text">{date.substring(11, 16)}</li> */}
                  </ul>
                </div>
                <ul className="location">
                  <li className="strong">Location:</li>
                  <li className="text">
                    {" "}
                    <div style={{ marginBottom: 10 }}>
                      <input
                        style={{ width: "100px", height: "20px" }}
                        type="text"
                        {...register("Location", { required: true })}
                      />
                      {errors.Location && <p>{errors.Location.message}</p>}
                    </div>
                  </li>
                </ul>
                <ul className="location">
                  <li className="strong">Founded By:</li>
                  <li className="text">
                    <div style={{ marginBottom: 10 }}>
                      <input
                        style={{ width: "100px", height: "20px" }}
                        type="text"
                        {...register("FundedBy", { required: true })}
                      />
                      {errors.FundedBy && <p>{errors.FundedBy.message}</p>}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="aboutContainer">
            <p className="aboutText">
              <div style={{ marginBottom: 10 }}>
                <h3 style={{ color: "#000000" }}>About</h3>
                <input type="text" {...register("About", { required: true })} />
                {errors.About && <p>{errors.About.message}</p>}
              </div>
            </p>
          </div>
          <div>
            <label>Photo</label>
            <input
              type="file"
              name="Photo"
              onInput={handleChange}
              {...register("Photo", { required: true })}
            />
            {errors.Photo && <p>{errors.Photo.message}</p>}
          </div>
          <div style={{ marginBottom: 10 }}>
            <label>Title</label>
            <input type="text" {...register("Title", { required: true })} />
            {errors.Title && <p>{errors.Title.message}</p>}
          </div>

          <div style={{ marginBottom: 10 }}>
            <label>Type</label>
            <input type="text" {...register("Type", { required: true })} />
            {errors.Type && <p>{errors.Type.message}</p>}
          </div>
          <label>Password</label>
          <input
            type="Password"
            {...register("Password", { required: true })}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEvent;
