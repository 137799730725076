import React, { useState, useEffect } from "react";
import axios from "axios";

import { useForm } from "react-hook-form";
import { Collection } from "./validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";

import "./workStyle.css";

const AddArtist = () => {
  const postURL = "https://api.artfactory.am/api/Collection/add/collection";

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({ resolver: yupResolver(Collection) });

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  });

  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    console.log(e.target.files);
    setFile(e.target.files[0]);
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("PhotoUrls", data.PhotoUrls[0]);
    formData.append("ArtistId", data.ArtistId);
    formData.append("Name", data.Name);
    formData.append("Date", data.Date);

    for (var p of formData) {
      console.log(p);
    }
    await axios
      .post(postURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          ApiKey: data.Password,
        },
      })
      .then((X) => {
        console.log("here");
      });
    // console.log("formData:::", file);
  };

  return (
    <div className="addArtistContainer">
      <h1>Add Collectoin</h1>

      <div className="section">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginBottom: 10 }}>
            <label>Artist Id</label>
            <input type="text" {...register("ArtistId", { required: true })} />
            {errors.ArtistId && <p>{errors.ArtistId.message}</p>}
          </div>
          <div style={{ marginBottom: 10 }}>
            <label>Collection Name</label>
            <input type="text" {...register("Name", { required: true })} />
            {errors.Name && <p>{errors.Name.message}</p>}
          </div>
          <div style={{ marginBottom: 10 }}>
            <label>Date</label>
            <input type="text" {...register("Date", { required: true })} />
            {errors.Date && <p>{errors.Date.message}</p>}
          </div>
          <div>
            <label>Photos</label>
            <input
              type="file"
              name="PhotoUrls"
              onInput={handleChange}
              multiple
              {...register("PhotoUrls", { required: true })}
            />
            {errors.PhotoUrls && <p>{errors.PhotoUrls.message}</p>}
          </div>
          <label>Password</label>
          <input
            type="Password"
            {...register("Password", { required: true })}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddArtist;
