import React, { useState } from "react";

import axios from "axios";
import { useForm } from "react-hook-form";
import { ArtistSchema } from "./validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";

import "./artStyle.css";

const AddArtist = () => {
  const postURL = "https://api.artfactory.am/api/Artist/add/new";

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitSuccessful },
    reset,
  } = useForm({ resolver: yupResolver(ArtistSchema) });

  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  });

  const [image, setImage] = useState("");

  const convert2base64 = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result.toString());
    };

    reader.readAsDataURL(file);
  };

  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    console.log(e.target.files);
    setFile(e.target.files[0]);
    convert2base64(e.target.files[0]);
    // convert2base64(file);
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("Avatar", data.Avatar[0]);
    formData.append("FirstName", data.FirstName);
    formData.append("LastName", data.LastName);
    formData.append("Position", data.Position);
    formData.append("Country", data.Country);
    formData.append("Biography", data.Biography);
    formData.append("Educations", data.Educations);
    formData.append("Exhibitions", data.Exhibitions);
    formData.append("Articles", data.Articles);
    formData.append("PhoneNumber", data.PhoneNumber);
    console.log(data.Password, "pass:::");

    for (var p of formData) {
      console.log(p);
    }
    await axios
      .post(postURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          ApiKey: data.Password,
        },
      })
      .then((X) => {
        console.log("here");
        console.log(data.Password, "pass:::");
      });
    data.FirstName = "";
    console.log("update", data.FirstName);
  };

  return (
    <div className="addArtistContainer">
      <h1>Add Artist</h1>
      <div className="section">
        <h2>Preview</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="topContainerArt">
            <div className="artist">
              <img className="ArtistImg" src={image} alt="" />
              <ul className="info2">
                <li className="name2">
                  <div style={{ marginBottom: 10 }}>
                    <label className="label1">First Name</label>
                    <input
                      type="text"
                      style={{ height: "20px", width: "120px" }}
                      {...register("FirstName", { required: true })}
                    />
                    {errors.FirstName && <p>{errors.FirstName.message}</p>}
                  </div>
                  <div style={{ marginBottom: 10 }}>
                    <label className="label1">Last Name</label>
                    <input
                      type="text"
                      style={{ height: "20px", width: "120px" }}
                      {...register("LastName", { required: true })}
                    />
                    {errors.LastName && <p>{errors.LastName.message}</p>}
                  </div>
                </li>
                <li className="position2">
                  {" "}
                  <div style={{ marginBottom: 10 }}>
                    <label className="label1">Position</label>
                    <input
                      style={{ height: "20px", width: "120px" }}
                      type="text"
                      {...register("Position", { required: true })}
                    />
                    {errors.Position && <p>{errors.Position.message}</p>}
                  </div>
                </li>
                <li className="country2">
                  {" "}
                  <div style={{ marginBottom: 10 }}>
                    <label className="label1">Country</label>
                    <input
                      style={{ height: "20px", width: "120px" }}
                      type="text"
                      {...register("Country", { required: true })}
                    />
                    {errors.Country && <p>{errors.Country.message}</p>}
                  </div>
                </li>
              </ul>
              <ul className="contactList2">
                <li className="contactItem">
                  Tel:
                  <div style={{ marginBottom: 10 }}>
                    <input
                      style={{ height: "20px", width: "120px" }}
                      type="text"
                      {...register("PhoneNumber", { required: true })}
                    />
                    {errors.PhoneNumber && <p>{errors.PhoneNumber.message}</p>}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="discription2">
            <ul className="discriptionText">
              <h2>Biography</h2>
              <p className="discriptionSubText" style={{ margin: "red" }}>
                <div style={{ marginBottom: 10 }}>
                  <input
                    type="text"
                    {...register("Biography", { required: true })}
                  />
                  {errors.Biography && <p>{errors.Biography.message}</p>}
                </div>
              </p>
              <h2>Education</h2>
              <ul className="discriptionList">
                <li>
                  <p className="discriptionSubText">
                    <div style={{ marginBottom: 10 }}>
                      <input
                        type="text"
                        {...register("Educations", { required: true })}
                      />
                      {errors.Educations && <p>{errors.Educations.message}</p>}
                    </div>
                  </p>
                </li>
              </ul>
              <h2>Exhibitions</h2>
              <ul className="discriptionList">
                <li>
                  <p className="discriptionSubText_02">
                    <div style={{ marginBottom: 10 }}>
                      <input
                        type="text"
                        {...register("Exhibitions", { required: true })}
                      />
                      {errors.Exhibitions && (
                        <p>{errors.Exhibitions.message}</p>
                      )}
                    </div>
                  </p>
                </li>
              </ul>
            </ul>
            <div className="discriptionImages">
              <img className="descrImg" src={file} alt="IMG Not Found" />
              <img className="descrImg" src={file} alt="IMG Not Found" />
              <img className="descrImg" src={file} alt="IMG Not Found" />
            </div>
          </div>

          <div style={{ marginBottom: 10 }}>
            <label>Articles</label>
            <input type="text" {...register("Articles", { required: true })} />
            {errors.Articles && <p>{errors.Articles.message}</p>}
          </div>

          <div>
            <label>Avatar</label>
            <input
              type="file"
              name="Avatar"
              onInput={handleChange}
              {...register("Avatar", { required: true })}
            />
            {errors.Avatar && <p>{errors.Avatar.message}</p>}
          </div>

          <label>Password</label>
          <input
            type="Password"
            {...register("Password", { required: true })}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddArtist;
