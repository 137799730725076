import React, { useState } from "react";

import axios from "axios";
import { useForm } from "react-hook-form";
import { TextSchema } from "./validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";

import "./EditText.css";

const EditText = () => {
  const postURL = "https://api.artfactory.am/api/Info/update";

  const [aboutBy, setAboutBy] = useState("");
  const [aboutUsSub, setAboutUsSub] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(TextSchema) });

  const onSubmit = async (data) => {
    console.log(data.Password, "pass:::");

    setAboutBy(data.aboutBy);
    console.log("aboutBy --->", aboutBy);

    setAboutUsSub(data.about);
    console.log("aboutUs --->", aboutUsSub);

    await axios
      .post(postURL, data, {
        headers: {
          ApiKey: data.Password,
        },
      })
      .then((X) => {
        console.log("here");
        console.log(data.Password, "pass:::");
      });
  };

  return (
    <div className="addArtistContainer">
      <h1>Edit Text</h1>

      <div className="section">
        <form onSubmit={handleSubmit(onSubmit)}>
          <label className="">About Us</label>
          <select {...register("aboutBy", { required: true })}>
            <option value="AboutUs">About Us</option>
            <option value="AboutYBAFMembership">About YBAF Membership</option>
            <option value="HomeLatitude">Home Latitude</option>
            <option value="AboutLatitude">About Latitude</option>
            <option value="HomeStudios">Home Studios</option>
            <option value="RequestToBuy">Request To Buy</option>
            <option value="AboutStudios">About Studios</option>
            <option value="AboutA1">Home A1</option>
            <option value="HomeA1">About A1</option>
          </select>
          <div style={{ marginBottom: 10 }}>
            <label className="">Text</label>
            <input type="text" {...register("about", { required: true })} />
            {errors.about && <p>{errors.about.message}</p>}
          </div>
          <label>Password</label>
          <input
            type="Password"
            {...register("Password", { required: true })}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditText;
