import React, { useEffect, useState } from "react";
import axios from "axios";

import Chevron from "react-chevron";

import { useForm } from "react-hook-form";
import del from "../../img/trash-bin.png";

import "./style.css";

const HomePage = () => {
  const [data, setData] = useState([]);
  const [dataWork, setDataWork] = useState([]);
  const [dataEvent, setDataEvent] = useState([]);

  let [dataList, updateDataList] = useState(data);

  const [pass, setPass] = useState("");

  const [openWorks, setOpenWorks] = useState(false);
  const [openEvents, setOpenEvents] = useState(false);
  const [openArtists, setOpenArtists] = useState(false);

  console.log("data", data);
  console.log("dataUp", dataList);
  console.log("dataWork", dataWork);
  console.log("dataEvent", dataEvent);

  const keyAxios = axios.create({
    headers: {
      ApiKey: pass,
    },
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermWork, setSearchTermWork] = useState("");
  const [searchTermEvent, setSearchTermEvent] = useState("");

  useEffect(() => {
    axios.get("https://api.artfactory.am/api/Artist/all").then(({ data }) => {
      setData(data);
    });

    axios
      .get("https://api.artfactory.am/api/works/all/work")
      .then(({ data }) => {
        setDataWork(data);
      });
    axios
      .get("https://api.artfactory.am/api/Event/all/events")
      .then(({ data }) => {
        setDataEvent(data.result);
      });
  }, []);

  const dell = (id) => {
    keyAxios.delete(
      `https://api.artfactory.am/api/Artist/delete?artistId=${id}`
    );
    updateDataList(dataList.filter((item) => item.id == item.id));
  };

  const dellEvent = (id) => {
    axios.delete(
      `https://api.artfactory.am/api/Event/delete/event?eventId=${id}`,
      {
        headers: {
          ApiKey: pass,
        },
      }
    );
    console.log("id", id);
  };

  const dellWork = (id) => {
    axios.delete(
      `https://api.artfactory.am/api/works/artist/works?artistId=${id}`,
      {
        headers: {
          ApiKey: pass,
        },
      }
    );
    console.log("id", id);
  };

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    setPass(data.Password);
    console.log("pass -->", pass);
  };

  return (
    <>
      <div className="allListContainer">
        <form
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <label
              style={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              Password
            </label>
            <input
              type="Password"
              {...register("Password", { required: true })}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <button type="submit">Submit</button>
          </div>
        </form>

        <div className="allButtons">
          <button onClick={() => setOpenArtists(true)} className="itemButton">
            Artists <Chevron direction="down" />
          </button>
          {openArtists && (
            <div className="list">
              <input
                type="text"
                placeholder="Search by name"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
                style={{
                  opacity: "0.7",
                  width: "350px",
                  height: "40px",
                  fontSize: "24px",
                  fontWeight: "400",
                }}
              />
              {data
                .filter((val) => {
                  if (setSearchTerm === "") {
                    return val;
                  } else if (
                    val.firstName
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    val.lastName
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((item) => (
                  <div className="allList" key={item.id}>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        background: `url(${item.avatarUrl}) center center/cover no-repeat`,
                      }}
                    ></div>
                    <p className="name">{item.firstName}</p>
                    <p className="name">{item.lastName}</p>
                    <p className="name">id: {item.id}</p>
                    <button
                      onClick={() => dell(item?.id)}
                      className="delButton"
                    >
                      <img src={del} className="delIcon" alt="icon not found" />
                    </button>
                  </div>
                ))}
            </div>
          )}
          <button onClick={() => setOpenEvents(true)} className="itemButton">
            Events <Chevron direction="down" />
          </button>
          {openEvents && (
            <div className="list">
              <input
                type="text"
                placeholder="Search by name"
                onChange={(event) => {
                  setSearchTermEvent(event.target.value);
                }}
                style={{
                  opacity: "0.7",
                  width: "350px",
                  height: "40px",
                  fontSize: "24px",
                  fontWeight: "400",
                }}
              />
              {dataEvent
                .filter((val) => {
                  if (setSearchTermEvent === "") {
                    return val;
                  } else if (
                    val.fundedBy
                      .toLowerCase()
                      .includes(searchTermEvent.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((item) => (
                  <div className="allList" key={item.id}>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        background: `url(${item.photoUrl}) center center/cover no-repeat`,
                      }}
                    ></div>
                    <p className="name">{item.name}</p>
                    <p className="name">{item.fundedBy}</p>
                    <p className="name">id: {item.id}</p>
                    <button
                      onClick={() => dellEvent(item?.id)}
                      className="delButton"
                    >
                      <img src={del} className="delIcon" alt="icon not found" />
                    </button>
                  </div>
                ))}
            </div>
          )}
          <button onClick={() => setOpenWorks(true)} className="itemButton">
            Works <Chevron direction="down" />
          </button>
          {openWorks && (
            <div className="list">
              <input
                type="text"
                placeholder="Search by Autor name"
                onChange={(event) => {
                  setSearchTermWork(event.target.value);
                }}
                style={{
                  opacity: "0.7",
                  width: "350px",
                  height: "40px",
                  fontSize: "24px",
                  fontWeight: "400",
                }}
              />
              {dataWork
                .filter((val) => {
                  if (setSearchTermWork === "") {
                    return val;
                  } else if (
                    val.autor
                      .toLowerCase()
                      .includes(searchTermWork.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((item) => (
                  <div className="allList" key={item.id}>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        background: `url(${item.photos}) center center/cover no-repeat`,
                      }}
                    ></div>
                    <p className="name">{item.workName}</p>
                    <p className="name">{item.autor}</p>
                    <p className="name">id: {item.id}</p>
                    <button
                      onClick={() => dellWork(item?.id)}
                      className="delButton"
                    >
                      <img src={del} className="delIcon" alt="icon not found" />
                    </button>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default HomePage;
